<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="true" key="1">
        <b-row class="p-2 mx-5 bg-gray-100 border rounded-lg shadow-sm">
          <b-col>
            <b-media no-body>
              <b-media-aside>
                <b-img
                  thumbnail
                  rounded
                  width="200"
                  size="25"
                  :src="logoHolder"
                />

                <div>
                  <image-uploader
                    class="hidden"
                    :debug="1"
                    :max-width="300"
                    output-format="verbose"
                    accept="image/*"
                    @onUpload="uploading = true"
                    @input="onFilePicked"
                  >
                    <label ref="refInputEl" slot="upload-label" for="fileInput">
                    </label>
                  </image-uploader>
                </div>
              </b-media-aside>
              <b-media-aside class="invisible">
                <b-img
                  thumbnail
                  rounded
                  width="200"
                  size="25"
                  :src="logoHolder"
                />

                <div>
                  <image-uploader
                    class="hidden"
                    :debug="1"
                    :max-width="300"
                    output-format="verbose"
                    accept="image/*"
                    @onUpload="uploading = true"
                    @input="onFilePicked"
                  >
                    <label ref="refInputEl" slot="upload-label" for="fileInput">
                    </label>
                  </image-uploader>
                </div>
              </b-media-aside>

              <b-media-body v-if="uploading" class="mt-75 ml-75">
                uploading {{ progress }}
              </b-media-body>
              <b-media-body v-else-if="complete" class="mt-75 ml-75">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="onPickFile"
                >
                  Upload
                </b-button>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="resetImage"
                >
                  Reset
                </b-button>
                {{ completedText }}

                <b-card-text>Allowed JPG, GIF or PNG</b-card-text>
              </b-media-body>
              <b-media-body v-else class="mt-75 ml-75">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="onPickFile"
                >
                  Upload
                </b-button>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="resetImage"
                >
                  Reset
                </b-button>

                <b-card-text>Allowed JPG, GIF or PNG</b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
        <validation-observer ref="generalValues" tag="form">
          <b-form class="mx-5 mt-2 bg-gray-100">
            <div
              class="flex flex-col items-center justify-center p-1 border rounded-lg shadow-sm"
            >
              <!-- 
  
  
              FIRST NAME FIELD
  
  
             -->
              <b-col cols="8">
                <validation-provider
                  #default="{ errors }"
                  name="business-name"
                  rules="required"
                >
                  <b-form-group
                    label="Business Name or Firm Name"
                    label-for="business-name"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      v-model="vData.businessName"
                      placeholder="Business Name"
                      name="business-name"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- 
    
    
               STREET NAME FIELD
    
    
               -->
              <b-col sm="8">
                <validation-provider
                  #default="{ errors }"
                  name="street"
                  rules="required"
                >
                  <b-form-group
                    label="Street Address"
                    label-for="street"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      v-model="vData.street"
                      placeholder="Street"
                      name="street"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- 
    
    
               CITY NAME FIELD
    
    
               -->
              <b-col sm="8">
                <validation-provider
                  #default="{ errors }"
                  name="city"
                  rules="required"
                >
                  <b-form-group
                    label="City"
                    label-for="city"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      v-model="vData.city"
                      placeholder="City"
                      name="city"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col sm="8">
                <b-row>
                  <!-- 
        
        
                   STATE NAME FIELD
        
        
                   -->
                  <b-col sm="9">
                    <validation-provider
                      #default="{ errors }"
                      name="state"
                      rules="required"
                    >
                      <b-form-group
                        label="State"
                        label-for="state"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-input
                          v-model="vData.state"
                          placeholder="State"
                          name="state"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- 
        
        
                   ZIP CODE FIELD
        
        
                   -->
                  <b-col sm="3">
                    <validation-provider
                      #default="{ errors }"
                      name="zip-code"
                      rules="required|digits:5"
                    >
                      <b-form-group
                        label="Zip Code"
                        label-for="zip-code"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-input
                          v-model="vData.zip"
                          placeholder="Zip"
                          name="zip-code"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <!-- 
    
    
                ZIP FIELD
    
    
               -->
              <b-col sm="8">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="email|required"
                >
                  <b-form-group
                    label="Primary Business Email"
                    label-for="email"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      v-model="vData.email"
                      name="email"
                      placeholder="Email"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- 
    
    
                OFFICE PHONE FIELD
    
    
               -->
              <b-col sm="8">
                <b-row>
                  <b-col sm="9">
                    <validation-provider
                      #default="{ errors }"
                      name="office-phone"
                      rules="required"
                    >
                      <b-form-group
                        label="Primary Office Phone"
                        label-for="office-phone"
                      >
                        <b-form-input
                          v-model="vData.phone"
                          name="office-phone"
                          placeholder="(555) 555-5555"
                          :state="errors.length > 0 ? false : null"
                          @blur="fOffice"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col sm="3">
                    <validation-provider
                      #default="{ errors }"
                      name="office-extension"
                      rules="numeric"
                    >
                      <b-form-group label="ext" label-for="office-extension">
                        <b-form-input
                          v-model="vData.phoneExtension"
                          name="office-extension"
                          placeholder="0000"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          The extension must be a number!
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>

              <!-- 
    
    
                FAX NUMBER FIELD
    
    
               -->
              <b-col sm="8">
                <b-row>
                  <b-col sm="9">
                    <validation-provider #default="{ errors }" name="fax">
                      <b-form-group label="Office Fax" label-for="fax">
                        <b-form-input
                          v-model="vData.fax"
                          name="fax"
                          placeholder="(555) 555-5555"
                          :state="errors.length > 0 ? false : null"
                          @blur="fFax"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col sm="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Fax Extension"
                      rules="numeric"
                    >
                      <b-form-group label="ext" label-for="fax-extension">
                        <b-form-input
                          v-model="vData.faxExtension"
                          name="fax-extension"
                          placeholder="0000"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>

              <!-- 
    
    
                TIME ZONE FIELD
    
    
               -->
              <b-col sm="8">
                <validation-provider
                  #default="{ errors }"
                  name="time-zone"
                  rules="required"
                >
                  <b-form-group
                    label="Time Zone"
                    label-for="time-zone"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="time-zone"
                      v-model="vData.timeZone"
                      class="bg-white"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="time-zone"
                      placeholder="Choose a TimeZone"
                      :options="timeZones"
                    />
                    <b-form-invalid-feedback
                      class=""
                      :state="errors.length > 0 ? false : null"
                    >
                      Please select AT LEAST one Time Zone.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- 
    
    
                TIME ZONE FIELD
    
    
               -->
              <b-col sm="8">
                <validation-provider
                  #default="{ errors }"
                  name="billing-contact"
                  rules="required"
                >
                  <b-form-group
                    label="Billing Primary Contact Person"
                    label-for="billing-contact"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="billing-contact"
                      v-model="vData.billingUser"
                      disabled
                      class="bg-white"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="billing-contact"
                      placeholder="Choose your billing primary contact"
                      :options="vData.accountUsers"
                    />
                    <b-form-invalid-feedback
                      class=""
                      :state="errors.length > 0 ? false : null"
                    >
                      Please select AT LEAST one Billing Contact.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <transition name="fade">
                <div
                  v-if="vData.billingUser === 'Add Billing Contact'"
                  class="bg-gray-200 border rounded-lg"
                >
                  <!-- customBillFirstName: "",
        customBillLastName: "",
        customBillPhone: "",
        customBillPhoneExtension: "",
        customBillEmail: "" -->
                  <p class="mt-1 ml-1 text-red-600">
                    New Billing Contact
                  </p>
                  <!-- 
  
  
              FIRST NAME FIELD
  
  
             -->
                  <b-col cols="8">
                    <validation-provider
                      #default="{ errors }"
                      name="CustFirstName"
                      rules="required"
                    >
                      <b-form-group
                        label="First Name"
                        label-for="cust-first-name"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-input
                          v-model="vData.customBillFirstName"
                          placeholder="First Name"
                          name="cust-first-name"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- 
  
  
             LAST NAME FIELD
  
  
             -->
                  <b-col cols="8">
                    <validation-provider
                      #default="{ errors }"
                      name="CustLastName"
                      rules="required"
                    >
                      <b-form-group
                        label="Last Name"
                        label-for="cust-last-name"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-input
                          v-model="vData.customBillLastName"
                          placeholder="Last Name"
                          name="cust-last-name"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- 
  
  
              EMAIL FIELD
  
  
             -->
                  <b-col cols="8">
                    <validation-provider
                      #default="{ errors }"
                      name="cust-email"
                      rules="email|required"
                    >
                      <b-form-group
                        label="Contact Email"
                        label-for="cust-email"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-input
                          v-model="vData.customBillEmail"
                          name="cust-email"
                          placeholder="Email"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- 
  
  
              PERSONAL PHONE FIELD
  
  
             -->
                  <b-col cols="8">
                    <b-row>
                      <b-col sm="9">
                        <validation-provider
                          #default="{ errors }"
                          name="cust-personal-phone"
                        >
                          <b-form-group
                            label="Personal Phone"
                            label-for="cust-personal-phone"
                          >
                            <b-form-input
                              v-model="vData.customBillPhone"
                              name="cust-personal-phone"
                              placeholder="(555) 555-5555"
                              :state="errors.length > 0 ? false : null"
                              @blur="custFPhone"
                            />
                            <b-form-invalid-feedback
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col sm="3">
                        <validation-provider
                          #default="{ errors }"
                          name="Phone Extension"
                          rules="numeric"
                        >
                          <b-form-group
                            label="ext"
                            label-for="cust-phone-extension"
                          >
                            <b-form-input
                              v-model="vData.customBillPhoneExtension"
                              name="cust-phone-extension"
                              placeholder="0000"
                            />
                            <b-form-invalid-feedback
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>
                </div>
              </transition>
              <b-col class="py-1 mt-5 text-center bg-gray-200 rounded-lg">
                <b-row class="px-2">
                  <b-col sm="9">Billing Information the Same?</b-col>
                  <b-col sm="3">
                    <b-form-checkbox
                      v-model="vData.billingSame"
                      name="check-button"
                      switch
                    >
                      No/Yes
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>

              <div v-if="!vData.billingSame">
                <!-- 
  
  
            BUSINESS NAME FIELD
  
  
             -->
                <div class="my-3 text-center">
                  <h3>Billing Information</h3>
                </div>
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    name="bill-business-name"
                    rules="required"
                  >
                    <b-form-group
                      label="Business or Firm Name"
                      label-for="bill-business-name"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-form-input
                        v-model="vData.billBusinessName"
                        placeholder="Business Name"
                        name="bill-business-name"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- 
  
  
             STREET NAME FIELD
  
  
             -->
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    name="bill-street"
                    rules="required"
                  >
                    <b-form-group
                      label="Street Address"
                      label-for="bill-street"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-form-input
                        v-model="vData.billStreet"
                        placeholder="Street"
                        name="bill-street"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- 
  
  
             CITY NAME FIELD
  
  
             -->
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    name="bill-city"
                    rules="required"
                  >
                    <b-form-group
                      label="City"
                      label-for="bill-city"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-form-input
                        v-model="vData.billCity"
                        placeholder="City"
                        name="bill-city"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <b-row>
                    <!-- 
    
    
               STATE NAME FIELD
    
    
               -->
                    <b-col sm="9">
                      <validation-provider
                        #default="{ errors }"
                        name="bill-state"
                        rules="required"
                      >
                        <b-form-group
                          label="State"
                          label-for="bill-state"
                          :state="errors.length > 0 ? false : null"
                        >
                          <b-form-input
                            v-model="vData.billState"
                            placeholder="State"
                            name="state"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- 
    
    
               ZIP CODE FIELD
    
    
               -->
                    <b-col sm="3">
                      <validation-provider
                        #default="{ errors }"
                        name="bill-zip-code"
                        rules="required|digits:5"
                      >
                        <b-form-group
                          label="Zip Code"
                          label-for="bill-zip-code"
                          :state="errors.length > 0 ? false : null"
                        >
                          <b-form-input
                            v-model="vData.billZip"
                            placeholder="Zip"
                            name="bill-zip-code"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- 
  
  
              EMAIL FIELD
  
  
             -->
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    name="bill-email"
                    rules="email|required"
                  >
                    <b-form-group
                      label="Primary Business Email"
                      label-for="bill-email"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-form-input
                        v-model="vData.billEmail"
                        name="bill-email"
                        placeholder="Email"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- 
  
  
              OFFICE PHONE FIELD
  
  
             -->
                <b-col>
                  <b-row>
                    <b-col sm="9">
                      <validation-provider
                        #default="{ errors }"
                        name="office-phone"
                      >
                        <b-form-group
                          label="Primary Office Phone"
                          label-for="bill-office-phone"
                        >
                          <b-form-input
                            v-model="vData.billOffice"
                            name="bill-office-phone"
                            placeholder="(555) 555-5555"
                            :state="errors.length > 0 ? false : null"
                            @blur="fBillOffice"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col sm="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Office Extension"
                        rules="numeric"
                      >
                        <b-form-group
                          label="ext"
                          label-for="bill-office-extension"
                        >
                          <b-form-input
                            v-model="vData.billOfficeExtension"
                            name="bill-office-extension"
                            placeholder="0000"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>

                <!-- 
  
  
              FAX NUMBER FIELD
  
  
             -->
                <b-col>
                  <b-row>
                    <b-col sm="9">
                      <validation-provider #default="{ errors }" name="fax">
                        <b-form-group label="Office Fax" label-for="bill-fax">
                          <b-form-input
                            v-model="vData.billFax"
                            name="fax"
                            placeholder="(555) 555-5555"
                            :state="errors.length > 0 ? false : null"
                            @blur="fBillFax"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col sm="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Phone Extension"
                        rules="numeric"
                      >
                        <b-form-group label="ext" label-for="fax-extension">
                          <b-form-input
                            v-model="vData.billFaxExtension"
                            name="bill-fax-extension"
                            placeholder="0000"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
              </div>
              <!--/ alert -->

              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  type="reset"
                  class="mt-2"
                  @click.prevent="getAccountData()"
                >
                  Reset Form
                </b-button>
              </b-col>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </transition>
  </div>
</template>

<script>
const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(",")
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1)
    n -= 1 // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime })
}
import {
  BFormCheckbox,
  BFormInvalidFeedback,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import { required, email, integer, digits, numeric } from "@validations"
import vSelect from "vue-select"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import ImageUploader from "vue-image-upload-resize"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import localLogoHolder from "./Your-Logo-here.png"
import timeZones from "./timeZones.js"

// import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
// import { ref } from "@vue/composition-api";

export default {
  components: {
    vSelect,
    BFormCheckbox,
    BImg,
    ImageUploader,
    BFormInvalidFeedback,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,

    ValidationObserver,
    ValidationProvider
  },
  directives: {
    Ripple
  },

  data() {
    return {
      // Image Uploading
      logoHolder: localLogoHolder,
      uploading: false,
      progress: 0,
      complete: false,
      completedText: "Saved!",
      hasImage: false,
      optionsLocal: "",
      image: null,

      //VALIDATORS
      required,
      integer,
      email,
      digits,
      numeric,

      // TIME ZONE ARRAY
      timeZones,

      // BILLING CONTACT ARRAY

      billingContacts: [],

      resetFormTrans: false,
      //LOCAL V-MODEL VARS
      imageUrl: "",
      generalData: {
        billingSame: true,
        billingUser: "",
        accountUsers: [],
        billingCustom: false,
        businessUuid: "",

        businessName: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        phoneExtension: "",
        fax: "",
        faxExtension: "",
        email: "",
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        // BILLING VARS
        billBusinessName: "",
        billStreet: "",
        billCity: "",
        billState: "",
        billZip: "",
        billOffice: "",
        billOfficeExtension: "",
        billFax: "",
        billFaxExtension: "",
        billEmail: "",
        // CUSTOM BILLING CONTACT
        customBillFirstName: "",
        customBillLastName: "",
        customBillPhone: "",
        customBillPhoneExtension: "",
        customBillEmail: ""
      },
      vData: {
        billingSame: true,
        billingUser: "",
        accountUsers: [],
        billingCustom: false,
        businessUuid: "",

        businessName: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        phoneExtension: "",
        fax: "",
        faxExtension: "",
        email: "",
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        // BILLING VARS
        billBusinessName: "",
        billStreet: "",
        billCity: "",
        billState: "",
        billZip: "",
        billOffice: "",
        billOfficeExtension: "",
        billFax: "",
        billFaxExtension: "",
        billEmail: "",
        // CUSTOM BILLING CONTACT
        // CUSTOM BILLING CONTACT
        customBillFirstName: "",
        customBillLastName: "",
        customBillPhone: "",
        customBillPhoneExtension: "",
        customBillEmail: ""
      }
    }
  },

  created() {
    this.getAccountData()
  },

  methods: {
    getAccountData() {
      this.$AuthAxios.instance
        .get("/api-access/user-data/account-details/") // eslint-disable-next-line no-unused-vars
        .then(async (res) => {
          function process(data) {
            if (data === 0) {
              return ""
            }
            return data
          }
          // eslint-disable-next-line no-unused-vars
          Object.keys(res.data.results).forEach((key, index) => {
            this.vData[key] = process(res.data.results[key])
          })

          this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
          this.logoHolder = await this.getImage()

          await setTimeout(() => {
            this.resetFormTrans = true
          }, 500)
          return
        })
    },
    updateAccountData() {
      if (this.vData.billingUser === "Add Billing Contact") {
        this.vData.billingCustom = true
      } else {
        this.vData.billingCustom = false
      }

      this.$AuthAxios.instance
        .put("/api-access/user-data/account-details/", this.vData) // eslint-disable-next-line no-unused-vars
        .then(async (res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "SUCCESS! UPDATED!",
              icon: "xCircleIcon",
              text: "Your info has successfully be updated!",
              variant: "success"
            }
          })
        })
    },
    async resetForm(created = false) {
      var self = this

      if (created != "created") {
        self.resetFormTrans = false
        await setTimeout(() => {
          this.$emit("reset")
        }, 500)
      } else {
        // eslint-disable-next-line no-unused-vars
        Object.keys(this.generalData).forEach(function(key, index) {
          self.vData[key] = self.generalData[key]
        })

        await setTimeout(() => {
          self.resetFormTrans = true
        }, 500)
      }
    },
    async getImage() {
      let fbStorage = this.$firebase.storage()
      let fbRef = fbStorage.ref()

      let url =
        (await fbRef
          .child(`users/business-logo/${this.vData.businessUuid}-logo.jpg`)
          .getDownloadURL() // eslint-disable-next-line no-unused-vars
          .then(async (url) => {
            return url
          })
          .catch(() => {})) || this.logoHolder
      return url
    },
    resetImage() {
      let fbStorage = this.$firebase.storage()
      let fbRef = fbStorage.ref()
      let userRef = fbRef.child(
        `users/business-logo/${this.vData.businessUuid}-logo.jpg`
      )
      userRef
        .delete()
        .then(() => {
          this.completedText = "Saved!"
          this.complete = false
          this.logoHolder = localLogoHolder
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "RESET!",
              icon: "CheckCircleIcon",
              text: "You have successfully reset your LOGO!",
              variant: "success"
            }
          })
        }) // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${
                error.code === "storage/object-not-found"
                  ? "No Photo!"
                  : "Uh-oh, an error occurred!"
              }`,
              icon: "xCircleIcon",
              text: ` Uh-Oh! \n \n ${
                error.code === "storage/object-not-found"
                  ? "Can't reset!"
                  : error.code
              }`,
              variant: "danger"
            }
          })
          // Uh-oh, an error occurred!
        })
    },

    custFPhone() {
      // FORMAT PHONE NUMBER
      var x = this.vData.customBillPhone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.vData.customBillPhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },
    fPhone() {
      // FORMAT PHONE NUMBER
      var x = this.vData.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.vData.phone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },
    fFax() {
      // FORMAT PHONE NUMBER
      var x = this.vData.fax
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.vData.fax = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },
    fOffice() {
      // FORMAT PHONE NUMBER
      var x = this.vData.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.vData.phone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },
    fBillFax() {
      // FORMAT PHONE NUMBER
      var x = this.vData.billFax
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.vData.billFax = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },
    fBillOffice() {
      // FORMAT PHONE NUMBER
      var x = this.vData.billOffice
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.vData.billOffice = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },

    onPickFile() {
      this.$refs.refInputEl.click()
    },
    onFilePicked(file) {
      let image = dataURLtoFile(file.dataUrl, file.info.name)

      this.hasImage = true

      let fbStorage = this.$firebase.storage()
      let fbRef = fbStorage.ref()
      let userRef = fbRef.child(
        `users/business-logo/${this.vData.businessUuid}-logo.jpg`
      )
      var uploadTask = userRef.put(image)

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          this.progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        }, // eslint-disable-next-line no-unused-vars
        (error) => {
          this.hasImage = false

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "ERROR!",
              icon: "XCircleIcon",
              text: "Your Logo Could Not Be Uploaded!",
              variant: "danger"
            }
          })

          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.logoHolder = downloadURL
            this.complete = true
            this.uploading = false

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "SAVED!",
                icon: "CheckCircleIcon",
                text:
                  "You have successfully saved your LOGO to: ${downloadURL}",
                variant: "success"
              }
            })
          })
        }
      )
    },
    async validate() {
      var self = this
      var status = false
      await this.$refs.generalValues.validate().then(
        await function(success) {
          if (success) {
            status = true
            self.updateAccountData()
          } else {
            status = false
          }
        }
      )

      return status
    }
  }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>



