<template>
  <setting-general
    v-if="finishCall"
    ref="userSettings"
    :key="updateKey"
    :general-data="generalData"
    @reset="resetComponent"
    @updateUser="updateUserData"
  />
</template>




<script>
import SettingGeneral from "./SettingGeneral.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  components: {
    SettingGeneral
  },
  data() {
    return {
      finishCall: false,
      userData: true,
      updateKey: 0,
      // User DATA

      generalData: {
        appUserId: "",
        accountId: "",
        userId: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        phoneExtension: "",
        fax: "",
        faxExtension: "",
        office: "",
        officeExtension: "",
        permissionLevel: this.$store.getters["auth/role"],
        is_billing_contact: false,
        is_account_owner: false
      }

      /* THE DATA STRUCTURE FOR THE RESPONSE WILL BE AS FOLLOWS TO BEGIN WITH
         I WILL NEED TO ADD THE NOTIFICATION DATA
      userData: {
        user_id: "String",
        first_name: "String",
        last_name: "String",
        phone_number: "Number", // Fromat 5555555555
        phone_extension: "Number",
        email_address: "String",
        is_billing_contact: "Boolean",
        is_account_owner: "Boolean"
      }
       */
    }
  },
  created() {
    this.getUserData()
  },
  methods: {
    resetComponent() {
      this.updateKey += 1
    },
    getUserData() {
      this.$AuthAxios.instance
        .get("/api-access/user-data/details/")
        .then((res) => {
          this.generalData.appUserId = this.$store.getters["auth/id"]
          this.generalData.accountId = res.data.results.account_id
          this.generalData.userId = res.data.results.user_id
          this.generalData.firstName = res.data.results.first_name
          this.generalData.lastName = res.data.results.last_name
          this.generalData.email = res.data.results.email_address
            ? res.data.results.email_address
            : this.$store.getters["auth/email"]
          this.generalData.phone = res.data.results.phone_number
            ? res.data.results.phone_number
            : ""
          this.generalData.phoneExtension = res.data.results.phone_extension
            ? res.data.results.phone_extension
            : ""

          this.generalData.fax = res.data.results.fax_number
          this.generalData.faxExtension = res.data.results.fax_extension
          this.generalData.office = res.data.results.office_phone
          this.generalData.officeExtension = res.data.results.office_extension

          this.generalData.is_billing_contact =
            res.data.results.is_billing_contact
          this.generalData.is_account_owner = res.data.results.is_account_owner

          this.finishCall = true
        })
    },
    async updateUserData(userDetails, waitFunc) {
      await this.$AuthAxios.instance
        .put("/api-access/user-data/details/", userDetails) // eslint-disable-next-line no-unused-vars
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "CHANGES SAVED!",
              icon: "CheckCircleIcon",
              text: "You have successfully updated your General Information!",
              variant: "success"
            }
          })
          waitFunc(true)
          this.generalData = userDetails
          return true
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error!",
              icon: "XCircleIcon",
              text: "Something went wrong!",
              variant: "warning"
            }
          })
          throw error
        })
    }
  }
}
</script>



