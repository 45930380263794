<template>
  <div v-if="setupStage === 0" class="px-2 auth-wrapper auth-v1">
    <div class="py-2 auth-inner">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="ml-1 brand-text text-primary"></h2>
        </b-link>
        <div>
          <b-card-title class="mb-1">
            <div v-if="$can('get', 'accountAdmin')">
              Now, Let's get your account Set up.
            </div>
            <div v-else>Let's get you set up.</div>
          </b-card-title>
          <b-card-text class="mb-2">
            <div v-if="$can('get', 'accountAdmin')" class="p-1">
              You will need some basic information for you and your business. If
              you need to quit at any time, don't worry, your progress will be
              saved. Click the button below when you are ready to continue.
            </div>
            <div v-else class="p-1">
              If you need to quit at any time, don't worry, your progress will
              be saved. Click the button below when you are ready to continue.
            </div>
          </b-card-text>
        </div>
        <div>
          <div>
            <b-button
              variant="primary"
              class="min-w-full"
              @click="incrementSetupStages(1)"
            >
              Continue
            </b-button>
          </div>
        </div>
      </b-card>
    </div>
  </div>
  <div v-else-if="setupStage === 4" class="px-2 auth-wrapper auth-v1">
    <div class="py-2 auth-inner">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="ml-1 brand-text text-primary"></h2>
        </b-link>
        <div>
          <b-card-title class="mb-1">
            <div>FINISHED! Your account setup is complete!.</div>
          </b-card-title>
          <b-card-text class="mb-2">
            <div class="p-1">
              If you ever need to update your information, you can do so under
              your account settings in the app.
            </div>
          </b-card-text>
        </div>
        <div>
          <div>
            <b-button
              variant="primary"
              class="min-w-full"
              @click="incrementSetupStages(5)"
            >
              Continue To Dashboard
            </b-button>
          </div>
        </div>
      </b-card>
    </div>
  </div>

  <div v-else class="px-2 auth-wrapper">
    <div class="py-2 auth-inner">
      <div class="mb-0">
        <setup-wizard @incrementSetupStages="incrementSetupStages" />
      </div>
    </div>
  </div>
</template>

<script>
import SetupWizard from "./SetupWizard"
import VuexyLogo from "@core/layouts/components/Logo.vue"
import "firebase/auth"
import { BCard, BLink, BCardText, BCardTitle, BButton } from "bootstrap-vue"

export default {
  components: {
    SetupWizard,
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BButton
  },
  data() {
    return {
      setupStage: this.$store.getters["auth/isSetupComplete"]
    }
  },
  created() {},

  methods: {
    incrementSetupStages(newValue) {
      let increment_url
      if (this.$can("get", "accountAdmin")) {
        increment_url = "/auth-api/increment-setup-stages/"
      } else {
        increment_url = "/auth-api/increment-setup-stages-user/"
      }
      newValue =
        newValue === 3 && !this.$can("get", "accountAdmin")
          ? newValue + 1
          : newValue
      this.$AuthAxios.instance
        .post(increment_url, { newStage: newValue })
        .then((res) => {
          this.setupStage = res.data
          this.$store.dispatch("auth/updateSetupStage", res.data)
          if (newValue === 5) {
            this.$router.push({ name: "home-main" })
          }
        })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
