<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="resetFormTrans" key="1">
        <b-row class="p-2 mx-5 bg-gray-100 border rounded-lg shadow-sm">
          <b-col>
            <b-media no-body>
              <b-media-aside>
                <b-avatar class="mr-5 shadow-md" size="100" :src="imageUrl">
                </b-avatar>

                <div>
                  <image-uploader
                    class="hidden"
                    :debug="1"
                    :max-width="300"
                    output-format="verbose"
                    accept="image/*"
                    @onUpload="uploading = true"
                    @input="onFilePicked"
                  >
                    <label ref="refInputEl" slot="upload-label" for="fileInput">
                    </label>
                  </image-uploader>
                </div>
              </b-media-aside>

              <b-media-body v-if="uploading" class="mt-75 ml-75">
                uploading {{ progress }}
              </b-media-body>
              <b-media-body v-else-if="complete" class="mt-75 ml-75">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="onPickFile"
                >
                  Upload
                </b-button>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="resetImage"
                >
                  Reset
                </b-button>
                {{ completedText }}

                <b-card-text>Allowed JPG, GIF or PNG</b-card-text>
              </b-media-body>
              <b-media-body v-else class="mt-75 ml-75">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="onPickFile"
                >
                  Upload
                </b-button>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="resetImage"
                >
                  Reset
                </b-button>

                <b-card-text>Allowed JPG, GIF or PNG</b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col>
            <div sm="6" class="p-1 border-2 rounded-lg ">
              <div class="w-full text-center">
                <span class="font-bold">Account UID: </span>42-104{{
                  vData.accountId
                }}
              </div>
              <div class="w-full text-center">
                <span class="font-bold">User UID: </span>U{{
                  vData.permissionLevel[0] | capitalize
                }}-420{{ vData.userId }}-69
              </div>

              <div class="w-full text-center">
                <span
                  class="flex flex-row items-center justify-center font-bold"
                  >Account Owner:
                  <feather-icon
                    :class="
                      `ml-1 ${
                        vData.is_account_owner ? 'text-success' : 'text-warning'
                      }`
                    "
                    :icon="
                      vData.is_account_owner ? 'CheckCircleIcon' : 'XCircleIcon'
                    "
                    size="15"
                /></span>
              </div>
              <div class="w-full text-center">
                <span class="font-bold">User Type: </span
                ><span class="underline">{{ vData.permissionLevel }}</span>
              </div>
            </div>
          </b-col>
        </b-row>

        <!--/ media -->

        <!-- form -->
        <validation-observer ref="generalValues" tag="form">
          <b-form class="mx-5 mt-2 bg-gray-100">
            <div
              class="flex flex-col items-center justify-center p-1 border rounded-lg shadow-sm"
            >
              <!-- 
  
  
              FIRST NAME FIELD
  
  
             -->
              <b-col sm="8">
                <validation-provider
                  #default="{ errors }"
                  name="FirstName"
                  rules="required"
                >
                  <b-form-group
                    label="First Name"
                    label-for="first-name"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      v-model="vData.firstName"
                      placeholder="First Name"
                      name="first-name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- 
  
  
             LAST NAME FIELD
  
  
             -->
              <b-col sm="8">
                <validation-provider
                  #default="{ errors }"
                  name="LastName"
                  rules="required"
                >
                  <b-form-group
                    label="Last Name"
                    label-for="last-name"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      v-model="vData.lastName"
                      placeholder="Last Name"
                      name="last-name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- 
  
  
              EMAIL FIELD
  
  
             -->
              <b-col sm="8">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="email|required"
                >
                  <b-form-group
                    label="Contact Email"
                    label-for="email"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      v-model="vData.email"
                      name="email"
                      placeholder="Email"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- 
  
  
              OFFICE PHONE FIELD
  
  
             -->
              <b-col sm="8">
                <validation-provider
                  #default="{ errors }"
                  name="office-phone"
                  rules="required"
                >
                  <b-row>
                    <b-col sm="9">
                      <b-form-group
                        label="Office Phone"
                        label-for="office-phone"
                      >
                        <b-form-input
                          v-model="vData.office"
                          name="office-phone"
                          placeholder="(555) 555-5555"
                          :state="errors.length > 0 ? false : null"
                          @blur="fOffice"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col sm="3">
                      <b-form-group label="ext" label-for="office-extension">
                        <b-form-input
                          v-model="vData.officeExtension"
                          name="office-extension"
                          placeholder="0000"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-provider>
              </b-col>
              <!-- 
  
  
              PERSONAL PHONE FIELD
  
  
             -->
              <b-col sm="8">
                <validation-provider
                  #default="{ errors }"
                  name="personal-phone"
                  rules="required"
                >
                  <b-row>
                    <b-col sm="9">
                      <b-form-group
                        label="Personal Phone"
                        label-for="personal-phone"
                      >
                        <b-form-input
                          v-model="vData.phone"
                          name="personal-phone"
                          placeholder="(555) 555-5555"
                          :state="errors.length > 0 ? false : null"
                          @blur="fPhone"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col sm="3">
                      <b-form-group label="ext" label-for="phone-extension">
                        <b-form-input
                          v-model="vData.phoneExtension"
                          name="phone-extension"
                          placeholder="0000"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-provider>
              </b-col>
              <!-- 
  
  
              FAX NUMBER FIELD
  
  
             -->
              <b-col sm="8">
                <validation-provider #default="{ errors }" name="fax">
                  <b-row>
                    <b-col sm="9">
                      <b-form-group label="Fax" label-for="fax">
                        <b-form-input
                          v-model="vData.fax"
                          name="fax"
                          placeholder="(555) 555-5555"
                          :state="errors.length > 0 ? false : null"
                          @blur="fFax"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col sm="3">
                      <b-form-group label="ext" label-for="fax-extension">
                        <b-form-input
                          v-model="vData.faxExtension"
                          name="fax-extension"
                          placeholder="0000"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-provider>
              </b-col>

              <!--/ alert -->

              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  type="reset"
                  class="mt-2"
                  @click.prevent="resetForm"
                >
                  Reset Form
                </b-button>
              </b-col>
            </div>
          </b-form>
        </validation-observer>
      </div>
      <div v-else key="2" class="invisible">
        <b-row class="p-2 mx-5 bg-gray-100 border rounded-lg shadow-sm">
          <b-col>
            <b-media no-body>
              <b-media-aside>
                <b-avatar class="mr-5 shadow-md" size="100" :src="imageUrl">
                </b-avatar>

                <div>
                  <image-uploader
                    class="hidden"
                    :debug="1"
                    :max-width="300"
                    output-format="verbose"
                    accept="image/*"
                    @onUpload="uploading = true"
                    @input="onFilePicked"
                  >
                    <label ref="refInputEl" slot="upload-label" for="fileInput">
                    </label>
                  </image-uploader>
                </div>
              </b-media-aside>

              <b-media-body v-if="uploading" class="mt-75 ml-75">
                uploading {{ progress }}
              </b-media-body>
              <b-media-body v-else-if="complete" class="mt-75 ml-75">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="onPickFile"
                >
                  Upload
                </b-button>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="resetImage"
                >
                  Reset
                </b-button>
                {{ completedText }}

                <b-card-text>Allowed JPG, GIF or PNG</b-card-text>
              </b-media-body>
              <b-media-body v-else class="mt-75 ml-75">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="onPickFile"
                >
                  Upload
                </b-button>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="resetImage"
                >
                  Reset
                </b-button>

                <b-card-text>Allowed JPG, GIF or PNG</b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col>
            <div sm="6" class="p-1 border-2 rounded-lg ">
              <div class="w-full text-center">
                <span class="font-bold">Account UID: </span>42-104{{
                  vData.accountId
                }}
              </div>
              <div class="w-full text-center">
                <span class="font-bold">User UID: </span>U{{
                  vData.permissionLevel[0] | capitalize
                }}-420{{ vData.userId }}-69
              </div>

              <div class="w-full text-center">
                <span
                  class="flex flex-row items-center justify-center font-bold"
                  >Account Owner:
                  <feather-icon
                    :class="
                      `ml-1 ${
                        vData.is_account_owner ? 'text-success' : 'text-warning'
                      }`
                    "
                    :icon="
                      vData.is_account_owner ? 'CheckCircleIcon' : 'XCircleIcon'
                    "
                    size="15"
                /></span>
              </div>
              <div class="w-full text-center">
                <span class="font-bold">User Type: </span
                ><span class="underline">{{ vData.permissionLevel }}</span>
              </div>
            </div>
          </b-col>
        </b-row>

        <!--/ media -->

        <!-- form -->
        <validation-observer ref="generalValues" tag="form">
          <b-form class="mx-5 mt-2 bg-gray-100">
            <div
              class="flex flex-col items-center justify-center p-1 border rounded-lg shadow-sm"
            >
              <!-- 
  
  
              FIRST NAME FIELD
  
  
             -->
              <b-col sm="8">
                <validation-provider
                  #default="{ errors }"
                  name="FirstName"
                  rules="required"
                >
                  <b-form-group
                    label="First Name"
                    label-for="first-name"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      v-model="vData.firstName"
                      placeholder="First Name"
                      name="first-name"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- 
  
  
             LAST NAME FIELD
  
  
             -->
              <b-col sm="8">
                <validation-provider
                  #default="{ errors }"
                  name="LastName"
                  rules="required"
                >
                  <b-form-group
                    label="Last Name"
                    label-for="last-name"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      v-model="vData.lastName"
                      placeholder="Last Name"
                      name="last-name"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- 
  
  
              EMAIL FIELD
  
  
             -->
              <b-col sm="8">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="email|required"
                >
                  <b-form-group
                    label="Contact Email"
                    label-for="email"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      v-model="vData.email"
                      name="email"
                      placeholder="Email"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- 
  
  
              OFFICE PHONE FIELD
  
  
             -->
              <b-col sm="8">
                <validation-provider
                  #default="{ errors }"
                  name="office-phone"
                  rules="required"
                >
                  <b-row>
                    <b-col sm="9">
                      <b-form-group
                        label="Office Phone"
                        label-for="office-phone"
                      >
                        <b-form-input
                          v-model="vData.office"
                          name="office-phone"
                          placeholder="(555) 555-5555"
                          :state="errors.length > 0 ? false : null"
                          @blur="fOffice"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col sm="3">
                      <b-form-group label="ext" label-for="office-extension">
                        <b-form-input
                          v-model="vData.officeExtension"
                          name="office-extension"
                          placeholder="0000"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-provider>
              </b-col>
              <!-- 
  
  
              PERSONAL PHONE FIELD
  
  
             -->
              <b-col sm="8">
                <validation-provider
                  #default="{ errors }"
                  name="personal-phone"
                >
                  <b-row>
                    <b-col sm="9">
                      <b-form-group
                        label="Personal Phone"
                        label-for="personal-phone"
                        rules="required"
                      >
                        <b-form-input
                          v-model="vData.phone"
                          name="personal-phone"
                          placeholder="(555) 555-5555"
                          :state="errors.length > 0 ? false : null"
                          @blur="fPhone"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col sm="3">
                      <b-form-group label="ext" label-for="phone-extension">
                        <b-form-input
                          v-model="vData.phoneExtension"
                          name="phone-extension"
                          placeholder="0000"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-provider>
              </b-col>
              <!-- 
  
  
              FAX NUMBER FIELD
  
  
             -->
              <b-col sm="8">
                <validation-provider #default="{ errors }" name="fax">
                  <b-row>
                    <b-col sm="9">
                      <b-form-group label="Fax" label-for="fax">
                        <b-form-input
                          v-model="vData.fax"
                          name="fax"
                          placeholder="(555) 555-5555"
                          :state="errors.length > 0 ? false : null"
                          @blur="fFax"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col sm="3">
                      <b-form-group label="ext" label-for="fax-extension">
                        <b-form-input
                          v-model="vData.faxExtension"
                          name="fax-extension"
                          placeholder="0000"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-provider>
              </b-col>

              <!--/ alert -->

              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  type="reset"
                  class="mt-2"
                  @click.prevent="resetForm"
                >
                  Reset Form
                </b-button>
              </b-col>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </transition>
  </div>
</template>

<script>
const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(",")
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1)
    n -= 1 // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime })
}
import {
  BFormInvalidFeedback,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import { required, email, numeric } from "@validations"

import { ValidationProvider, ValidationObserver } from "vee-validate"
import ImageUploader from "vue-image-upload-resize"
import { mapGetters } from "vuex"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

// import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
// import { ref } from "@vue/composition-api";

export default {
  components: {
    ImageUploader,
    BFormInvalidFeedback,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    ValidationObserver,
    ValidationProvider
  },
  directives: {
    Ripple
  },
  props: {
    generalData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // Image Uploading
      uploading: false,
      progress: 0,
      complete: false,
      completedText: "Saved!",
      hasImage: false,
      optionsLocal: "",
      image: null,

      //VALIDATORS
      required,
      numeric,
      email,

      //LOCAL V-MODEL VARS
      resetFormTrans: false,
      vData: {}
    }
  },
  computed: {
    ...mapGetters({
      imageUrl: "auth/avatar"
    })
  },
  created() {
    this.resetForm("created")
    this.fOffice()
    this.fFax()
    this.fPhone()
  },

  methods: {
    async resetForm(created = false) {
      var self = this

      if (created != "created") {
        self.resetFormTrans = false
        await setTimeout(() => {
          this.$emit("reset")
        }, 500)
      } else {
        // eslint-disable-next-line no-unused-vars
        Object.keys(this.generalData).forEach(function(key, index) {
          self.vData[key] =
            self.generalData[key] === "none" ? "" : self.generalData[key]
        })
        await setTimeout(() => {
          self.resetFormTrans = true
        }, 500)
      }
    },
    resetImage() {
      let fbStorage = this.$firebase.storage()
      let fbRef = fbStorage.ref()
      let userRef = fbRef.child(
        `users/profile-pic/${this.generalData.appUserId}profile-pic.jpg`
      )
      userRef
        .delete()
        .then(() => {
          this.$store.dispatch("auth/updateAvatar", {
            url: this.$store.getters["auth/startAvatar"],
            base: true
          })
          this.completedText = "Saved!"
          this.complete = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "RESET!",
              icon: "CheckCircleIcon",
              text: "You have successfully reset your avatar!",
              variant: "warning"
            }
          })
          this.$emit("reset")
        }) // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${
                error.code === "storage/object-not-found"
                  ? "No Photo!"
                  : "Uh-oh, an error occurred!"
              }`,
              icon: "xCircleIcon",
              text: ` Uh-Oh! \n \n ${
                error.code === "storage/object-not-found"
                  ? "Can't reset, no photo uploaded!"
                  : error.code
              }`,
              variant: "danger"
            }
          })
          // Uh-oh, an error occurred!
        })

      this.$store.dispatch("auth/updateAvatar", {
        url: this.$store.getters["auth/startAvatar"],
        base: true
      })
    },
    fPhone() {
      // FORMAT PHONE NUMBER
      var x = this.vData.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.vData.phone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },
    fFax() {
      // FORMAT PHONE NUMBER
      var x = this.vData.fax
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.vData.fax = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },
    fOffice() {
      // FORMAT PHONE NUMBER
      var x = this.vData.office
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.vData.office = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },

    onPickFile() {
      this.$refs.refInputEl.click()
    },
    onFilePicked(file) {
      let image = dataURLtoFile(file.dataUrl, file.info.name)

      this.hasImage = true

      const fileReader = new FileReader()
      fileReader.addEventListener("load", () => {
        this.$store.dispatch("auth/updateAvatar", {
          url: fileReader.result,
          base: false
        })
      })
      fileReader.readAsDataURL(image)

      let fbStorage = this.$firebase.storage()
      let fbRef = fbStorage.ref()
      let userRef = fbRef.child(
        `users/profile-pic/${this.generalData.appUserId}profile-pic.jpg`
      )
      var uploadTask = userRef.put(image)

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          this.progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        }, // eslint-disable-next-line no-unused-vars
        (error) => {
          this.hasImage = false

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "ERROR!",
              icon: "XCircleIcon",
              text: "Your Photo Could Not Be Uploaded!",
              variant: "danger"
            }
          })

          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.$store.dispatch("auth/updateAvatar", {
              url: downloadURL,
              base: false
            })
            this.complete = true
            this.uploading = false

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "SAVED!",
                icon: "CheckCircleIcon",
                text: "You have successfully saved your Avatar!",
                variant: "success"
              }
            })
          })
        }
      )
    },
    async validate() {
      var self = this
      var status = false
      await this.$refs.generalValues.validate().then(async function(success) {
        if (success) {
          // eslint-disable-next-line no-unused-vars
          const waitFunc = function(res) {}
          await self.$emit("updateUser", self.vData, waitFunc)

          status = true
        } else {
          status = false
        }
      })

      return status
    }
  }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>