<template>
  <div
    class="flex items-center justify-center max-w-6xl p-1 rounded-lg xl:min-w-6xl lg:p-10"
  >
    <div>
      <form-wizard
        ref="signUpWizard"
        color="#7367F0"
        title="Account Setup"
        :start-index="tab - 1"
        :subtitle="null"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="steps-transparent"
        @on-complete="formSubmitted"
      >
        <!-- user details tab -->
        <!-- review tab -->
        <tab-content
          title="Change Password"
          icon="feather icon-lock"
          :before-change="validationTabOne"
        >
          <settings-password ref="tab1" @skip="passwordSkip" />
        </tab-content>
        <tab-content
          title="Account Owner Info"
          icon="feather icon-user"
          :before-change="validationTabTwo"
        >
          <user-settings ref="tab2" />
        </tab-content>
        <!-- business details tab -->
        <tab-content
          v-if="$can('get', 'accountAdmin')"
          title="Business Info"
          icon="feather icon-file-text"
          :before-change="validationTabThree"
        >
          <business-settings ref="tab3" />
        </tab-content>

        <!-- finish tab -->
        <tab-content title="Finished" icon="feather icon-flag"> </tab-content>
      </form-wizard>
    </div>
  </div>
</template>

<script>
import SettingsPassword from "./SettingPassword"
import UserSettings from "./user-settings/UserSettings"
import BusinessSettings from "./BusinessInformation"
import { FormWizard, TabContent } from "vue-form-wizard"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import "vue-form-wizard/dist/vue-form-wizard.min.css"
import { mapGetters } from "vuex"

export default {
  name: "AccountSetupWizard",
  components: {
    SettingsPassword,
    BusinessSettings,
    FormWizard,
    TabContent,
    UserSettings
  },

  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      tab: "auth/isSetupComplete"
    })
  },

  methods: {
    passwordSkip() {
      this.$refs.signUpWizard.nextTab()
    },
    async validationTabOne() {
      var self = this
      if (self.tab <= 1) {
        return new Promise((resolve, reject) => {
          this.$refs.tab1.validate(resolve, reject).then(function(val) {
            if (val) {
              self.$emit("incrementSetupStages", 2)

              return resolve(true)
            } else {
              return reject()
            }
          })
        })
      }
      return true
    },
    async validationTabTwo() {
      var self = this
      return new Promise((resolve, reject) => {
        this.$refs.tab2.$refs.userSettings
          .validate(resolve, reject)
          .then(function(val) {
            if (val) {
              self.$emit("incrementSetupStages", 3)
              return resolve(true)
            } else {
              return reject()
            }
          })
      })
    },
    async validationTabThree() {
      var self = this
      return new Promise((resolve, reject) => {
        this.$refs.tab3.validate(resolve, reject).then(function(val) {
          if (val) {
            self.$emit("incrementSetupStages", 4)
            return resolve(true)
          } else {
            return reject()
          }
        })
      })
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Form Submitted",
          icon: "EditIcon",
          variant: "success"
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
