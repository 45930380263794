<template>
  <div>
    <!-- form -->
    <validation-observer ref="generalValues" tag="form">
      <b-form>
        <h5 class="mb-2">Please Choose a New Password</h5>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              vid="reauth"
              name="Current Password"
              rules="required"
            >
              <b-form-group
                label="Old Password"
                label-for="account-old-password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    placeholder="Old Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                  >{{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <div class="d-flex justify-content-between">
              <label for="login-password">Password</label>
              <b-link :to="{ name: 'auth-forgot-password' }">
                <small>Forgot Password?</small>
              </b-link>
            </div>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="New Password"
              :rules="`required|is:${RetypePassword}`"
            >
              <b-form-group
                label-for="account-new-password"
                label="New Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="New Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback
                  :state="errors.length > 0 && validated ? false : null"
                >
                  {{
                    errors[0] === "New Password is not valid"
                      ? "Passwords do not match"
                      : errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Retype Password"
              :rules="`required|is:${newPasswordValue}`"
            >
              <b-form-group
                label-for="account-retype-new-password"
                label="Retype New Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="New Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback
                  :state="errors.length > 0 && validated ? false : null"
                >
                  {{
                    errors[0] === "Retype Password is not valid"
                      ? "Passwords do not match"
                      : errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="secondary"
              class="mt-1"
              @click="$emit('reset')"
            >
              Reset Form
            </b-button>
            <b-button
              v-if="tab > 1"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-primary"
              class="float-right mt-1 ml-4"
              @click="$emit('skip')"
            >
              Skip
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BLink,
  BInputGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormGroup,
  BButton,
  BForm,
  BRow,
  BCol,
  BInputGroupAppend
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import { required } from "@validations"
import { extend } from "vee-validate"

import { ValidationProvider, ValidationObserver } from "vee-validate"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { mapGetters } from "vuex"

export default {
  components: {
    BLink,
    BInputGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  data() {
    return {
      required,
      validated: false,
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password"
    }
  },

  computed: {
    ...mapGetters({
      tab: "auth/isSetupComplete"
    }),
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password" ? "EyeIcon" : "EyeOffIcon"
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password" ? "EyeIcon" : "EyeOffIcon"
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon"
    }
  },
  created() {
    // eslint-disable-next-line no-unused-vars
    extend("is", (value) => {
      return this.newPasswordValue === this.RetypePassword
    })
  },
  methods: {
    async reauthenticate() {
      // eslint-disable-next-line no-unused-vars
      const user = this.$firebase.auth().currentUser

      // TODO(you): prompt the user to re-provide their sign-in credentials
      // eslint-disable-next-line no-unused-vars
      const credential = this.passwordValueOld

      await this.$firebase
        .auth()
        .signInWithEmailAndPassword(user.email, credential)
        // eslint-disable-next-line no-unused-vars
        .then((authUser) => {
          return this.updatePassword()
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "XCircleIcon",
              text: `${error}`,
              variant: "danger"
            }
          })

          this.$refs.generalValues.setErrors({
            reauth: ["Your Password Is Incorrect"]
          })
          throw error
        })
    },

    async updatePassword() {
      const user = this.$firebase.auth().currentUser

      await user
        .updatePassword(this.newPasswordValue)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "SUCCESS!",
              icon: "CheckCircleIcon",
              text: "Your Password Has Been Successfully Updated!",
              variant: "success"
            }
          })
          return true
          // Update successful.
        }) // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "XCircleIcon",
              text: `${error}`,
              variant: "danger"
            }
          })
          throw error
          // An error ocurred
          // ...
        })
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password"
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password"
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password"
    },
    async validate() {
      var status = false
      var self = this
      await this.$refs.generalValues.validate().then(async function(success) {
        if (success) {
          await self
            .reauthenticate()
            .then(() => {
              status = true
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error) => {
              status = false
            })
        } else {
          self.validated = true
          status = false
          return
        }
      })

      return status
    }
  }
}
</script>
